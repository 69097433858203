import Aos from "aos";
import React, { useEffect } from 'react';

import logoImage from '../../assets/images/feature.png';

const Feature = () => {
    useEffect(function () {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <div className="react_popular_topics pt---100 pb---70">
            <div className="container ">
                <div className="flex flex-col justify-center items-center " >
                    <h1 className="text-4xl font-semibold" data-aos="fade-up">Our features</h1>
                    <p className="mt-5 text-gray-600 text-lg" data-aos="fade-up">
                        Our product, Thunder Player, quickly loads your M3U playlist and plays
                        your favorite channels without any glitches.
                    </p>
                    <div className="grid lg:grid-cols-3 grid-cols-1 gap-10 mt-10 w-full">
                        <div className="cursor-pointer transform rounded-xl px-8 pb-8 pt-3 w-full bg-white shadow transition duration-300 hover:-translate-y-4" data-aos="fade-up" data-aos-delay="0">
                            <div className="">
                                <div className='flex justify-between'>
                                    <h3 className="text-xl font-semibold pt-3">Simple UI</h3>
                                    <img src={logoImage} alt="image" className="w-12 h-12" />
                                </div>
                                <p className="mt-1 text-gray-600 text-lg">
                                    We provide a simple UI, but with best user experience.
                                </p>
                            </div>
                        </div>
                        {/* <div className="cursor-pointer transform rounded-xl px-8 pb-8 pt-3 w-full bg-white shadow transition duration-300 hover:-translate-y-4" data-aos="fade-up" data-aos-delay="100">
                            <div className="">
                                <div className='flex justify-between'>
                                    <h3 className="text-xl font-semibold pt-3">Support All Resolutions</h3>
                                    <img src={logoImage} alt="image" className="w-12 h-12" />
                                </div>
                                <p className="mt-1 text-gray-600 text-lg">
                                    45 + landing and supported pages to Build a professional
                                    website.
                                </p>
                            </div>
                        </div> */}
                        <div className="cursor-pointer transform rounded-xl px-8 pb-8 pt-3 w-full bg-white shadow transition duration-300 hover:-translate-y-4" data-aos="fade-up" data-aos-delay="200">
                            <div className="">
                                <div className='flex justify-between'>
                                    <h3 className="text-xl font-semibold pt-3">Fast Streaming</h3>
                                    <img src={logoImage} alt="image" className="w-12 h-12" />
                                </div>
                                <p className="mt-1 text-gray-600 text-lg">
                                    Out product supports MX, VLC player, and Fast Streaming
                                </p>
                            </div>
                        </div>
                        <div className="cursor-pointer transform rounded-xl px-8 pb-8 pt-3 w-full bg-white shadow transition duration-300 hover:-translate-y-4" data-aos="fade-up" data-aos-delay="300">
                            <div className="">
                                <div className='flex justify-between'>
                                    <h3 className="text-xl font-semibold pt-3">Multilingual</h3>
                                    <img src={logoImage} alt="image" className="w-12 h-12" />
                                </div>
                                <p className="mt-1 text-gray-600 text-lg">
                                    You can select languages in movies and series
                                </p>
                            </div>
                        </div>
                        <div className="cursor-pointer transform rounded-xl px-8 pb-8 pt-3 w-full bg-white shadow transition duration-300 hover:-translate-y-4" data-aos="fade-up" data-aos-delay="400">
                            <div className="">
                                <div className='flex justify-between'>
                                    <h3 className="text-xl font-semibold pt-3">Continuous updates</h3>
                                    <img src={logoImage} alt="image" className="w-12 h-12" />
                                </div>
                                <p className="mt-1 text-gray-600 text-lg">
                                    We continually deploy improvements and new updates to Thunder Player
                                    apps.
                                </p>
                            </div>
                        </div>
                        <div className="cursor-pointer transform rounded-xl px-8 pb-8 pt-3 w-full bg-white shadow transition duration-300 hover:-translate-y-4" data-aos="fade-up" data-aos-delay="400">
                            <div className="">
                                <div className='flex justify-between'>
                                    <h3 className="text-xl font-semibold pt-3">Free support</h3>
                                    <img src={logoImage} alt="image" className="w-12 h-12" />
                                </div>
                                <p className="mt-1 text-gray-600 text-lg">
                                    Get 24/7 free support for instant assistance, ensuring help is
                                    available around the clock.
                                </p>
                            </div>
                        </div>
                        <div className="cursor-pointer transform rounded-xl px-8 pb-8 pt-3 w-full bg-white shadow transition duration-300 hover:-translate-y-4" data-aos="fade-up" data-aos-delay="300">
                            <div className="">
                                <div className='flex justify-between'>
                                    <h3 className="text-xl font-semibold pt-3">Register for free</h3>
                                    <img src={logoImage} alt="image" className="w-12 h-12" />
                                </div>
                                <p className="mt-1 text-gray-600 text-lg">
                                    You can get the app for Samsung, LG, Windows, Android, and iOS/macOS.
                                </p>
                            </div>
                        </div>
                        <div className="cursor-pointer transform rounded-xl px-8 pb-8 pt-3 w-full bg-white shadow transition duration-300 hover:-translate-y-4" data-aos="fade-up" data-aos-delay="400">
                            <div className="">
                                <div className='flex justify-between'>
                                    <h3 className="text-xl font-semibold pt-3">Add your playlists</h3>
                                    <img src={logoImage} alt="image" className="w-12 h-12" />
                                </div>
                                <p className="mt-1 text-gray-600 text-lg">
                                    Manage your playlists in the app or on website.
                                </p>
                            </div>
                        </div>
                        <div className="cursor-pointer transform rounded-xl px-8 pb-8 pt-3 w-full bg-white shadow transition duration-300 hover:-translate-y-4" data-aos="fade-up" data-aos-delay="400">
                            <div className="">
                                <div className='flex justify-between'>
                                    <h3 className="text-xl font-semibold pt-3">Activate your account</h3>
                                    <img src={logoImage} alt="image" className="w-12 h-12" />
                                </div>
                                <p className="mt-1 text-gray-600 text-lg">
                                    Activate your device via Google Pay, Apple Pay in the app or Stripe, Paypal on the website.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Feature;